export const webappConstants = {
  access_logs_types: [
    "setPasswd",
    "login",
    "chngPasswd",
    "rstPasswd",
    "frgtPasswd",
    "logout",
    "export",
    "import",
    "orgSwitch",
  ],
  account_logs_types: [
    "userAdded",
    "userEdited",
    "orgAdded",
    "orgEdited",
    "orgEditedUpdated",
    "orgEditedUpdated",
    "mainContactUpdated",
    "userAddedUpdated",
    "tempUserAdded",
    "riderSignup",
    "settings",
    "userEditedUpdated",
    "marketSegment",
    "monthly_invoice",
    "thirdPartyAccessGrantedRevoked",
    "systemSetting",
    "marketSegmentSettings",
  ],
  user_settings_possible_keys: [
    "admin_notifications",
    "admin_issues",
    "admin_pr",
    "receive_sms",
    "admin_status",
    "scheduler_notifications",
    "notification_language",
  ],
  audit_logs_types: [
    "ride",
    "appt",
    "patient",
    "tracking",
    "PR",
    "recurrence_rides",
  ],
  tracking_logs_types: ["tracking"],
  providers: {
    uber: 1,
    lyft: 2,
    primary: 2,
    secondary: 1,
  },
  roles: {
    MASTER_SUPER_ADMIN: "mastersuperadmin",
    SUPER_ADMIN: "superadmin",
    SCHEDULING_ADMIN: "Scheduling admin",
    RIDE_MANAGER: "admin",
    ORG_ADMIN: "orgadmin",
    ORG_SUPERADMIN: "org_superadmin",
    RIDER_ADMIN: "riderAdmin",
    RIDER_USER: "riderUser",
    TEMP_RIDER: "tempRider",
    TEMP_BUSINESS: "tempBusiness",
    API_USER: "apiuser",
    PRODUCTION_OPERATION: "productionOperation",
    NETWORK_OPERATION: "networkOperation",
    MICRO_SERVICE: "micro_service",
    PROVIDER_MANAGER: "providerManager",
    INVITED_PATIENT: "invitedPatient",
    OC_REPORTING: "oc_reporting",
    ORG_REPORTING: "org_reporting",
  },
  languages: {
    english: 1,
    spanish: 2,
    creole: 3,
    mandarin: 4,
    cantonese: 5,
    russian: 6,
    portuguese: 7,
    other: 8,
  },
  statuses: {
    PENDING_APPROVAL: {
      type: "pending",
      status: "Pending Approval",
      belongs: "appointment",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    CONFIRMED: {
      type: "scheduled",
      status: "Confirmed",
      belongs: "both",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: false,
    },
    RIDE_BOOKED: {
      type: "active",
      status: "Ride Booked",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RECURRENCE_RIDE_BOOKED: {
      type: "active",
      status: "Recurrence Ride Booked",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    GET_READY: {
      type: "active",
      status: "Get Ready",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    FAILED: {
      type: "issue",
      status: "Failed",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    FINDING_DRIVER: {
      type: "active",
      status: "Finding Driver",
      belongs: "ride",
      error_level: 1,
      queue: "findingDriver",
      exit_sqs: false,
    },
    DRIVER_CONFIRMED: {
      type: "active",
      status: "Driver Confirmed",
      belongs: "ride",
      error_level: 1,
      queue: "driverConfirmed",
      exit_sqs: false,
      rebookTryAlert: {
        count: 2,
        error_level: 1,
      },
    },
    DRIVER_RECONFIRMED: {
      type: "active",
      status: "Driver Reconfirmed",
      belongs: "ride",
      error_level: 1,
      queue: "driverConfirmed",
      exit_sqs: false,
    },
    DRIVER_ARRIVED: {
      type: "active",
      status: "Driver Arrived",
      belongs: "ride",
      error_level: 2,
      queue: "driverArrived",
      exit_sqs: false,
      rebookTryAlert: {
        count: 2,
        error_level: 1,
      },
    },
    PATIENT_ENROUTE: {
      type: "active",
      status: "Patient Enroute",
      belongs: "ride",
      error_level: 1,
      queue: "patientEnroute",
      exit_sqs: false,
      rebookTryAlert: {
        count: 2,
        error_level: 1,
      },
    },
    RIDE_COMPLETED: {
      type: "active",
      status: "Ride Completed",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    VARIANCE_UNDER: {
      type: "active",
      status: "Variance Under",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: true,
    },
    VARIANCE_OVER: {
      type: "active",
      status: "Variance Over",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: true,
    },
    APPOINTMENT_COMPLETED: {
      type: "completed",
      status: "Appointment Completed",
      belongs: "appointment",
      error_level: 1,
      queue: "",
      exit_sqs: true,
      smsStatus: "Appointment Completed",
    },
    PENDING_RETURN_REQUEST: {
      type: "pending return",
      status: "Pending Return Request",
      belongs: "appointment",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    RIDE_CANCELED: {
      type: "completed",
      status: "Ride Canceled",
      belongs: "ride",
      error_level: 4,
      queue: "bookCab",
      exit_sqs: true,
    },
    RIDE_REJECTED: {
      type: "completed",
      status: "Rejected",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    APPOINTMENT_CANCELED: {
      type: "canceled",
      status: "Appointment Canceled",
      belongs: "appointment",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    LATE_ARRIVAL: {
      type: "issue",
      status: "Late Arrival",
      belongs: "ride",
      error_level: 2,
      queue: "patientEnroute",
      exit_sqs: false,
    },
    DRIVER_CANCELED: {
      type: "issue",
      status: "Driver Canceled",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    NO_DRIVER_AVAILABLE: {
      type: "issue",
      status: "No Driver Available",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    MISSED_SURGE_APPROVAL: {
      type: "issue",
      status: "Missed Surge Approval",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    UATP_OTHER: {
      type: "issue",
      status: "Failed-Other",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    MANUALLY_RESOLVED: {
      type: "completed",
      status: "Manually Resolved",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    SURGE_APPROVAL_NEEDED: {
      type: "issue",
      status: "Surge Approval Needed",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: false,
    },
    SURGE_APPROVED: {
      type: "active",
      status: "Surge Approved",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: false,
    },
    APPOINTMENT_REJECTED: {
      type: "rejected",
      status: "Appointment Rejected",
      belongs: "appointment",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    PENDING_RIDE: {
      type: "pending",
      status: "pending",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    POTENTIAL_UNAVAILABILITY: {
      type: "issue",
      status: "Potential Unavailability",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: false,
    },
    APPOINTMENT_POTENTIAL_UNAVAILABILITY: {
      type: "issue",
      status: "Potential Unavailability",
      belongs: "appointment",
      error_level: 2,
      queue: "",
      exit_sqs: false,
    },
    VARIANCE_ISSUE: {
      type: "issue",
      status: "Variance Issue",
      belongs: "ride",
      error_level: 2,
      queue: "",
      exit_sqs: true,
    },
    PENDING_NEXT_RIDE: {
      type: "pending next",
      status: "Pending Next Ride",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    PATIENT_DELETED: {
      type: "issue",
      status: "Patient Deleted",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: true,
    },
    DEPARTURE_OVERDUE: {
      type: "issue",
      status: "Departure Overdue",
      belongs: "ride",
      error_level: 2,
      queue: "",
      exit_sqs: false,
    },
    PENDING_SUBMISSION: {
      type: "issue",
      status: "Pending Submission",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    PATIENT_NO_SHOW: {
      type: "issue",
      status: "Patient No Show",
      belongs: "both",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    REQUEST_OVERDUE: {
      type: "issue",
      status: "Request Overdue",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: true,
    },
    SPECIAL_RIDE: {
      type: "special_ride",
      status: "Special Ride",
      belongs: "ride",
      error_level: 3,
      queue: "",
      exit_sqs: true,
    },
    SPECIAL_RIDE_COMPLETE: {
      type: "special_ride_completed",
      status: "Special Ride",
      belongs: "ride",
      error_level: 2,
      queue: "",
      exit_sqs: true,
    },
    FAILED_CC_AUTH: {
      type: "completed",
      status: "Failed CC Auth",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: true,
    },
    FAILED_CC_PAYMENT: {
      type: "issue",
      status: "Failed CC Payment",
      belongs: "ride",
      error_level: 1,
      queue: "bookCab",
      exit_sqs: true,
    },
    FAILED_FUSE: {
      type: "issue",
      status: "Failed Fuse",
      belongs: "ride",
      error_level: 2,
      queue: "bookCab",
      exit_sqs: false,
    },
    ARRIVAL_TIME_EXCEEDED: {
      type: "issue",
      status: "Arrival Time Exceeded",
      belongs: "ride",
      error_level: 2,
      queue: "driverConfirmed",
      exit_sqs: false,
    },
    PENDING_RIDE_REQUEST: {
      type: "pending",
      status: "Pending Ride Request",
      error_level: 1,
    },
    RETRO_QUEUED: {
      type: "retro_queued",
      status: "Retro Queued",
      belongs: "ride",
      error_level: 1,
    },
    READY_TO_PROCESS: {
      type: "pending",
      status: "Ready To Process",
      belongs: "ride",
      error_level: 1,
    },
    OFFER_CREATED: {
      type: "alert",
      status: "Offer Created",
      belongs: "ride",
      error_level: 1,
    },
    OFFER_REJECTED: {
      status: "Offer Rejected",
      belongs: "ride",
      error_level: 1,
    },
    OFFER_EXPIRED: {
      status: "Offer Expired",
      belongs: "ride",
      error_level: 1,
    },
    RESCINDED: {
      status: "Rescinded",
      belongs: "ride",
      error_level: 1,
    },
    ORDER_RECEIVED: {
      type: "pending",
      status: "Order Received",
      belongs: "appointment",
      error_level: 1,
    },
    ORDER_QUEUED: {
      status: "Order Queued",
      belongs: "appointment",
      error_level: 1,
    },
    OFFER_RESCINDED: {
      status: "Offer Rescinded",
      belongs: "appointment",
      error_level: 1,
    },
    UATP: {
      type: "alert",
      status: "UATP",
      belongs: "ride",
      error_level: 2,
      queue: "",
      exit_sqs: false,
    },
    NEPF: {
      type: "alert",
      status: "NEPF",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    NDA: {
      type: "alert",
      status: "NDA",
      belongs: "ride",
      error_level: 2,
      queue: "",
      exit_sqs: false,
    },
    ORDER_UPDATED: {
      type: "alert",
      status: "Order Updated",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    OFFERED: {
      type: "processing",
      status: "Offered",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_RECEIVED_APPT_TIME: {
      smsStatus: "Ride Order Received Appt Time",
      status: "Ride Order Received Appt Time",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_RECEIVED: {
      smsStatus: "Ride Order Received",
      status: "Ride Order Received",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_RECEIVED_RECURRENCE: {
      smsStatus: "Ride Order Received Recurrence",
      status: "Ride Order Received Recurrence",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED: {
      smsStatus: "Ride Order Confirmed",
      status: "Ride Order Confirmed",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED_SOLE_PROPRIETOR: {
      smsStatus: "Ride Order Confirmed Sole Proprietor",
      status: "Ride Order Confirmed Sole Proprietor",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED_AGENCY: {
      smsStatus: "Ride Order Confirmed Agency",
      status: "Ride Order Confirmed Agency",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_DRIVER_ARRIVED: {
      smsStatus: "Ride Order Driver Arrived",
      status: "Ride Order Driver Arrived",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_RATING: {
      smsStatus: "Ride Order Rating",
      status: "Ride Order Rating",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_PENDING_RETURN_REQUEST: {
      smsStatus: "Ride Order Pending Return Request",
      status: "Ride Order Pending Return Request",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_PATIENT_ENROUTE: {
      smsStatus: "Ride Order Patient Enroute",
      status: "Ride Order Patient Enroute",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_DRIVER_ARRIVED_10_MIN: {
      smsStatus: "Ride Order Driver Arrived 10MIN",
      status: "Ride Order Driver Arrived 10MIN",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    TWENTY_FOUR_HOUR_CONFIRMATION_MISSING: {
      type: "alert",
      status: "24 Hour Confirmation Missing",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    CONFIRM_MEMBER_PICKUP: {
      type: "issue",
      status: "Confirm Member Pickup",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    CONFIRM_MEMBER_DROPOFF: {
      type: "issue",
      status: "Confirm Member Dropoff",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    PENDING_RE_OFFER: {
      type: "alert",
      status: "Pending - Re-Offer",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    NO_DRIVER_ASSIGNED: {
      type: "issue",
      status: "No Driver Assigned",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    PROVIDER_NO_SHOW: {
      type: "completed",
      status: "Provider No Show",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_PENDING_RETURN_REQUEST_DRIVER: {
      smsStatus: "Ride Order Pending Return Request Driver",
      status: "Ride Order Pending Return Request Driver",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED_DISPATCH_NOTES: {
      smsStatus: "Ride Order Confirmed Dispatch Notes",
      status: "Ride Order Confirmed Dispatch Notes",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED_10MIN_SOLE_PROPRIETOR: {
      smsStatus: "Ride Order Confirmed 10Min Sole Proprietor",
      status: "Ride Order Confirmed 10Min Sole Proprietor",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_CONFIRMED_10MIN_AGENCY: {
      smsStatus: "Ride Order Confirmed 10Min Agency",
      status: "Ride Order Confirmed 10Min Agency",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_DRIVER_ARRIVED_APP: {
      smsStatus: "Ride Order Driver Arrived App",
      status: "Ride Order Driver Arrived App",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    DELETED: {
      type: "deleted",
      status: "Deleted",
      belongs: "both",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    VALIDATION_FAILED: {
      type: "failed",
      status: "Validation Failed",
      belongs: "both",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    DRIVER_CONFIRMED_WITH_NOTES: {
      type: "active",
      status: "Driver Confirmed With Notes",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    DRIVER_RECONFIRMED_WITH_NOTES: {
      type: "active",
      status: "Driver Reconfirmed With Notes",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    DRIVER_INVALID_RESPONSE: {
      type: "active",
      status: "Ride Order Driver Invalid Response",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    OFFERED_NDA: {
      type: "alert",
      status: "Offered NDA",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    RIDE_ORDER_DRIVER_CONFIRMED_APP: {
      smsStatus: "Ride Order Driver Confirmed App",
      status: "Ride Order Driver Confirmed App",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    PATIENT_INVALID_RESPONSE_APP: {
      type: "active",
      status: "Ride Order Patient Invalid Response App",
      smsStatus: "Ride Order Patient Invalid Response App",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    DRIVER_POOR_RATING_APP: {
      type: "active",
      status: "Ride Order Driver Poor Rating App",
      smsStatus: "Ride Order Driver Poor Rating App",
      belongs: "ride",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
    PENDING_DISPOSITION: {
      type: "completed",
      status: "Pending Disposition",
      belongs: "both",
      error_level: 1,
      queue: "",
      exit_sqs: false,
    },
  },
  exportErrors: {
    noRecords: "No records found",
  },
  lyft_errors: {
    scheduled_ride_conflict:
      "You already have a scheduled ride around this time. To schedule this new ride, cancel your previous request.",
    user_already_in_ride:
      "The passenger is already in a ride. Finish current ride before requesting another",
  },
  variance_type: {
    billable: 1,
    waived: 2,
    variance_under: 3,
    variance_over: 4,
  },
  paymentTypes: {
    manual: 1,
    cc: 2,
    bank_account: 3,
  },
  dynamicExport: {
    available_columns: {
      pr: [
        {
          text: "Order ID",
          code: "external_order_id",
        },
        {
          text: "File Number",
          code: "file_number",
        },
        { text: "First Name", code: "first_name" },
        { text: "Last Name", code: "last_name" },
        { text: "Rider Phone", code: "patient_phone" },
        { text: "Requester Name", code: "requester_name" },
        { text: "Relay Invoice Number", code: "invoice_number" },
        { text: "Relay Invoice Date", code: "invoice_date" },
        { text: "Trip Date", code: "appointment_date" },
        { text: "Time Zone", code: "time_zone" },
        { text: "Ride Status", code: "ride_status" },
        { text: "Relay Monitoring Fee", code: "client_cost" },
        { text: "Ride Cost", code: "ride_cost" },
        { text: "Ride Final Cost", code: "ride_final_cost" },
        { text: "Cab Request Time", code: "cab_request_time" },
        { text: "Distance (Miles)", code: "distance" },
        { text: "Trip Type", code: "appointment_type" },
        { text: "Actual Pickup Address", code: "actual_pick_up_address" },
        {
          text: "Actual Destination Address",
          code: "actual_destination_address",
        },
        { text: "Variance Issue", code: "variance_issue" },
        { text: "Variance Type", code: "variance_type" },
        { text: "Surge", code: "surge" },
        { text: "Vendor", code: "provider_name" },
        {
          text: "Requested Pickup Address",
          code: "requested_pick_up_address",
        },
        {
          text: "Requested Dropoff Address",
          code: "requested_destination_address",
        },
        { text: "Ride Id(Vendor Uber/Lyft)", code: "ride_id" },
        { text: "Organization", code: "organization_name" },
        {
          text: "Client Name",
          code: "client_name",
          roles: [
            "mastersuperadmin",
            "productionOperation",
            "superadmin",
            "admin",
            "Scheduling admin",
            "networkOperation",
            "providerManager",
            "oc_reporting",
          ],
        },
        { text: "Organization Client Id", code: "org_client_id" },
        { text: "Parent Organization Id", code: "parent_org_id" },
        { text: "Monthly Invoice Number", code: "monthly_invoice_number" },
        { text: "Rider Satisfaction Rating", code: "rider_satisfaction" },
        { text: "Vendor Rating", code: "provider_rating" },
        { text: "Rider Comments", code: "rider_comments" },
        { text: "Requested Pickup Time", code: "requested_pickup_time" },
        { text: "Pick Up Time", code: "actual_pickup_time" },
        { text: "Actual Drop Off Time", code: "actual_drop_off_time" },
        {
          text: "Requested Drop Off Time",
          code: "requested_drop_off_time",
        },
        { text: "Estimated Vendor Cost", code: "estimated_vendor_cost" },
        { text: "Actual Vendor Cost", code: "actual_vendor_cost" },
        { text: "Estimated Mileage", code: "estimated_mileage" },
        { text: "Actual Mileage", code: "actual_mileage" },
        { text: "Questionnaire Version", code: "questionnaire_version" },
        {
          text: "Questionnaire Questions",
          code: "questionnaire_questions",
        },
        { text: "Questionnaire Answers", code: "questionnaire_answers" },
        { text: "Billing Method", code: "sso_billing_type" },
        { text: "Payer name", code: "sso_payer_name" },
        { text: "Payer ID", code: "sso_payer_id" },
        { text: "Claim Number", code: "sso_claim_number" },
        { text: "DOB", code: "sso_dob" },
        { text: "DOI", code: "sso_doi" },
        { text: "Adjuster First Name", code: "sso_adjuster_first_name" },
        { text: "Adjuster Last Name", code: "sso_adjuster_last_name" },
        { text: "Adjuster Email", code: "sso_adjuster_email" },
        {
          text: "Requesting Organization",
          code: "requesting_organization",
        },
        { text: "Adjuster Denial Flag", code: "sso_adjuster_denial_flag" },
        {
          text: "One Call No Show Fee",
          code: "one_call_no_show_fee",
          roles: [
            "mastersuperadmin",
            "superadmin",
            "admin",
            "Scheduling admin",
            "productionOperation",
            "networkOperation",
          ],
        },
        {
          text: "Custom Identifier",
          code: "custom_identifier",
        },
      ],
      mt: [
        { text: "First Name", code: "first_name" },
        { text: "Last Name", code: "last_name" },
        { text: "Time Zone", code: "time_zone" },
        { text: "Actual Pickup Address", code: "actual_pick_up_address" },
        {
          text: "Actual Destination Address",
          code: "actual_destination_address",
        },
        { text: "Distance (Miles)", code: "distance" },
        { text: "Ride Start Date Time", code: "ride_start_date_time" },
        { text: "Ride End Date Time", code: "ride_end_date_time" },
        { text: "Trip Type", code: "appointment_type" },
      ],
      vi: [
        { text: "Enterprise Name", code: "company_name" },
        { text: "Device ID", code: "device_id" },
        { text: "Start Time", code: "start_time" },
        { text: "End Time", code: "end_time" },
        { text: "Minutes", code: "minutes" },
        { text: "Call Date", code: "call_date" },
        { text: "Service", code: "service" },
        { text: "Agent Number", code: "agent_number" },
        { text: "To Language", code: "language" },
        { text: "Account Identifier", code: "account_identifier" },
        { text: "Account Identifier 2", code: "account_identifier_2" },
        { text: "Account Identifier 3", code: "account_identifier_3" },
        { text: "Access Code", code: "access_code" },
        { text: "Agency Case Number", code: "agency_case_number" },
        { text: "Campus or Site Name", code: "campus_or_site_name" },
        { text: "Case Record Number", code: "case_record_number" },
        { text: "Claim Number", code: "claim_number" },
        { text: "Cost Center Number", code: "cost_center_number" },
        { text: "Department Choice", code: "department_choice" },
        { text: "Department Freeform", code: "department_freeform" },
        { text: "Employee Badge Number", code: "employee_badge_number" },
        { text: "Officer or FF Name", code: "officer_name" },
        { text: "Rider DOB", code: "dob" },
        { text: "Rider First Name", code: "patient_first_name" },
        { text: "Rider Last Name", code: "patient_last_name" },
        { text: "Rider EMR", code: "patient_emr" },
        { text: "Rider MRN", code: "patient_mrn" },
        { text: "Rider Account Number", code: "patient_account_number" },
        { text: "Vendor First Name", code: "provider_first_name" },
        { text: "Vendor Last Name", code: "provider_last_name" },
        { text: "Purpose Of Encounter", code: "purpose_of_encounter" },
        { text: "Type Of Visit", code: "type_of_visit" },
        { text: "Visit Number", code: "visit_number" },
        { text: "Unit or Desk Number", code: "unit_or_desk_number" },
        { text: "Total Cost", code: "cost" },
      ],
      opi: [
        { text: "Company Name", code: "company_name" },
        { text: "Session ID", code: "session_id" },
        { text: "Referral Date", code: "referral_date" },
        { text: "Start Time", code: "opi_start_time" },
        { text: "End Time", code: "opi_end_time" },
        { text: "Billable Minutes", code: "billable_minutes" },
        { text: "Service", code: "service" },
        { text: "Caller First Name", code: "patient_first_name" },
        { text: "Caller Last Name", code: "patient_last_name" },
        { text: "Caller Phone", code: "patient_phone" },
        { text: "OPI Questions", code: "opi_questions" },
        { text: "OPI Answers", code: "opi_answers" },
        { text: "Total Cost", code: "cost" },
      ],
    },
  },
  relationship: {
    self: "Self",
    mother: "Mother",
    father: "Father",
    brother: "Brother",
    sister: "Sister",
  },
  login_lock: {
    allowed_attempts: 3,
    lock_duration_in_seconds: 300,
  },
  org_class: {
    BUSINESS: "business",
    RIDER: "rider",
  },
  passport: {
    strategy: "saml",
    saml: {
      path: "/sso/login/callback",
    },
  },
  provider_status_update_wait_time_seconds: 50,
  randomise_status_update_wait_time: false,
  webhookRetryMaxCount: 8,
  webhookEvents: {
    rideStatusChanged: "ride.status.updated",
  },
  MAX_SESSION_NOT_FOUND_RETRIES: 5,
  MAX_TRIP_AUTO_REBOOK_RETRIES: 2,
  RIDER_SMS_EXPIRY: 1800,
  ONE_CALL_ORGANISATION_NAME: "One Call Care Management",
  cache_time_for_phone_number_type: 1800,
  defaultPhoneType: "mobile",
  MasterorgLevel: [1],
  MasterRoles: [
    "mastersuperadmin",
    "superadmin",
    "Scheduling admin",
    "admin",
    "productionOperation",
    "networkOperation",
    "providerManager",
    "oc_reporting",
  ],
  providerIds: [1, 2],
  alerts: {
    late_arrival: {
      minimum_check_period: 300,
      maximum_checks: 5,
    },
  },
};
